.ui.segment.authentication--container {
  overflow: hidden;

  .field {
    text-align: left;
  }
}

.authentication--logo {
  max-width: 100%;
}

.authentication {
  &--welcome {
    margin: 1em 0px;
    font-size: 1.5em;
    text-transform: uppercase;
  }
}

.fullscreen-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @media (max-width: 480px) {
    max-width: 100%;
  }

  > .fullscreen-center-wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;

    > .ui.grid > .column.column--auth-content {
      max-width: 450px;

      @media (max-width: 480px) {
        max-width: 100%;
      }
    }
  }
}

.login-form__options {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .form-login {
    &--accept-legal-notice-label {
      margin-left: 10px;
      margin-bottom: 0px;
    }
  }
}

.link--secondary {
  font-style: italic;
  white-space: nowrap;
  color: rgb(128, 128, 128);
}

.link--forgotten-password {
  display: block;
  text-align: right;
  font-style: italic;
  white-space: nowrap;
  color: rgb(128, 128, 128);
}

.text--not-yet-registered {
  text-align: justify;
  font-size: 0.95rem;
}

@media (max-width: 768px) {
  .fullscreen-container {
    margin: 0 auto;
  }
}

@media (max-width: 768px) and (min-width: 320px) {
  .fullscreen-container {
    .ui.grid {
      display: flex;
    }
  }
}

@media (max-width: 480px) {
  .fullscreen-container {
    padding: 5px;
    display: block;
    overflow-x: hidden;

    & > .grid > .column {
      margin-top: 8px;
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}

.LoginBanner {
  margin: -1em -1em 1em -1em;
  cursor: pointer;
  display: block;

  & img {
    width: 100%;
    display: block;
  }
}

.LoginFooter {
  display: block;
  height: 70px;
  margin: 10vh auto 0;
}

.Login--MessageWrapper {
  display: flex;
  flex-direction: column;
  margin: 0.5em 0;
}

.Login--MessageWrapper .ui.message {
  text-align: left;
  display: block !important;
}

.scrolling-pane {
  width: 100%;
  text-align: left;
  white-space: nowrap;
  margin-left: 0;
  transition: margin 0.5s ease-in-out;

  &-wrapper {
    overflow: hidden;
    position: relative;
  }

  &__panel {
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }
}
