iframe.block--video__qa {
  border: 0;
}

.block--video--countdown {
  position: relative;

  &__container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .countdown__label {
      color: white;
      font-size: 20px;
      margin-bottom: 16px;
    }
  }
}
